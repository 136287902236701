import React, { useState } from 'react';
import './FullscreenArea.css';
import { Link } from 'react-router-dom';

const FullscreenArea = ({ isOpen, onClose }) => {
  // const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false); // State to toggle the sub-menu
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const checkTouchDevice = () => {
  //     setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
  //   };
  //   checkTouchDevice();
  //   window.addEventListener('resize', checkTouchDevice);
  //   return () => window.removeEventListener('resize', checkTouchDevice);
  // }, []);

  // const handleLinkClick = (path) => {
  //   onClose(); // Close the fullscreen area
  //   navigate(path); // Navigate to the desired page
  // };

  const toggleServicesMenu = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  if (!isOpen) return null;

  return (
    <div className="fullscreen-area">
      <div className="fullscreen-left">
        <Link to="/" onClick={onClose}>
          <img            
            className="pop-logo"
            src="https://level-contractor.thepreview.pro/images/logo-w.png"
            alt="Level Contractor"
          />
        </Link>
        <ul className="fullscreen-menu">
          <li><Link to="/" onClick={onClose}>Home</Link></li>
          <li><Link to="/about-us" onClick={onClose}>About</Link></li>
          <li className="services-menu"><Link to="/services" onClick={onClose}> Services</Link>
            <span onClick={toggleServicesMenu} className="services-toggle" > 
              <span className={`arrow ${isServicesOpen ? 'open' : ''}`}></span>
            </span>
            {isServicesOpen && (
              <div className='services-dropdown'>
              <ul className="sub-menu">
                <li><Link to="/kitchen-remodeling" onClick={onClose}>Kitchen Remodeling</Link></li>
                <li><Link to="/bathroom-remodeling" onClick={onClose}>Bathroom Remodeling</Link></li>
                <li><Link to="/home-remodeling" onClick={onClose}>Home Remodeling</Link></li>
                <li><Link to="/room-additions" onClick={onClose}>Room Additions</Link></li>
                <li><Link to="/garage-conversions" onClick={onClose}>Garage Conversions</Link></li>
                <li><Link to="/adu" onClick={onClose}>ADU (Accessory Dwelling Units)</Link></li>
                <li><Link to="/landscaping" onClick={onClose}>Landscaping</Link></li>
                <li><Link to="/roofing" onClick={onClose}>Roofing</Link></li>
                <li><Link to="/concrete-driveways" onClick={onClose}>Concrete & Driveways</Link></li>
                <li><Link to="/new-home-construction">New Home Construction</Link></li>
                <li><Link to="/exterior-interior-painting" onClick={onClose}>Exterior & Interior Painting</Link></li>
                <li><Link to="/window-door-installation" onClick={onClose}>Window & Door Installation</Link></li>
                <li><Link to="/3d-build" onClick={onClose}>3D Build Architecture</Link></li>
                <li><Link to="/outdoor-patio-remodeling" onClick={onClose}>Outdoor Patio Remodeling</Link></li>
              </ul>
              </div>
            )}
          </li>
          <li><Link to="/work" onClick={onClose}>Our Work</Link></li>
          <li><Link to="/project" onClick={onClose}>Projects</Link></li>
          <li><Link to="/reviews" onClick={onClose}>Reviews</Link></li>
          <li><Link to="/locations" onClick={onClose}>Service Area</Link></li>
          <li><Link to="/faq" onClick={onClose}>FAQ</Link></li>
          <li><Link to="/finance" onClick={onClose}>Finance</Link></li>
          <li><Link to="/blog" onClick={onClose}>Blog</Link></li>
          <li><Link to="/contact" onClick={onClose}>Contact</Link></li>
        </ul>
      </div>
      <div className="fullscreen-right">
        <img
          src="https://level-contractor.thepreview.pro/images/Navigation-Bar.webp"
          alt="Remodeling"
          className="fullscreen-image"
        />
        {/* <footer className="fullscreen-footer">
          <span>2125 Chamomile ct San Ramon, Ca 94582</span>
          <span>levelcontractor.office@gmail.com</span>
        </footer> */}
      </div>
      <button className="close-button" onClick={onClose} aria-label="Close Fullscreen Area">
      —
      </button>
    </div>
  );
};

export default FullscreenArea;
