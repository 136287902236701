import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.css"; // Add this CSS file for styling

const HomeWork = () => {
  const [works, setWorks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWorks = async () => {
      try {
        const response = await fetch(
          "https://level-contractor.thepreview.pro/Backend/admin/api/home-work-api.php"
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setWorks(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchWorks();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="home-work-container">
      <div className="heading-our-work">
        <h3>Our Works</h3>
      </div>
      <div className="home-work-grid">
        {works.map((work, index) => (
          <div
            key={work.id}
            className={`home-work-card ${
              index % 2 === 0 ? "vertical-card" : "horizontal-card"
            }`}
          >
            <Link to="/work">
              <img
                src={`https://level-contractor.thepreview.pro/Backend/admin/uploads/${work.image}`}
                alt={work.hwork_heading}
                className="home-work-image"
              />
              <h5 className="home-work-heading">{work.hwork_heading}</h5>
              <p className="home-service-details-link">Details</p>
            </Link>
          </div>
        ))}
      </div>
      <div className="category-buttons work-btn">
                <Link to="/work">
                  <button>See More Work</button>
                </Link>
      </div>
    </div>
  );
};

export default HomeWork;
