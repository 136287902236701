import React, { useState, useEffect } from 'react';

const Work = () => {
  const [works, setWorks] = useState([]);
  const [filteredWorks, setFilteredWorks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    fetch('https://level-contractor.thepreview.pro/Backend/admin/api/our-work-api.php')
      .then(response => response.json())
      .then(data => {
        setWorks(data);
        
        // Extract unique categories from the data
        const uniqueCategories = [...new Set(data.map(item => item.category))];
        setCategories(uniqueCategories);

        // Select a random category by default
        if (uniqueCategories.length > 0) {
          const randomCategory = uniqueCategories[Math.floor(Math.random() * uniqueCategories.length)];
          setSelectedCategory(randomCategory);

          // Filter works based on the randomly selected category
          const filtered = data.filter(work => work.category === randomCategory);
          setFilteredWorks(filtered);

          // Preload images for the selected category
          preloadImages(filtered);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const preloadImages = (workItems) => {
    workItems.forEach(work => {
      new Image().src = work.before_image;
      new Image().src = work.after_image;
    });
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    const filtered = works.filter(work => work.category === category);
    setFilteredWorks(filtered);

    // Preload images for the selected category
    preloadImages(filtered);
  };

  return (
    <div className='work-page'>
      <h2>Our Work</h2>
      
      {/* Render Category Buttons (Without "All") */}
      <div className="category-buttons">
        {categories.map((category) => (
          <button
            key={category}
            className={selectedCategory === category ? 'active' : ''}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Display Filtered Works */}
      <div className="work-gallery">
        {filteredWorks.map((work) => (
          <div key={work.id} className="work-item">
            {/* <BeforeAfterSlider beforeImage={work.before_image} afterImage={work.after_image} /> */}
            <img src={work.after_image} alt={work.title} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
