import React, { useState } from 'react'
import '../../src/assets/css/index.css'
import '../../src/assets/css/custom-frontend-lite.min.css'
import '../../src/assets/css/level-core.min.css'
import '../../src/assets/css/main.min.css'
import FullscreenArea from './FullSreenArea/FullscreenArea'
import { Link } from 'react-router-dom'
import BottomNav from './BottomNav'

const Mheader = () => {
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false)

  const openFullscreen = () => {
    setIsFullscreenOpen(true)
  }

  const closeFullscreen = () => {
    setIsFullscreenOpen(false)
  }

  return (
    <>
      <header id='qodef-page-mobile-header' role='banner'>
        <div id='qodef-page-mobile-header-inner' className=''>
          <Link
            to='/'
            className='qodef-mobile-header-logo-link qodef-height--set qodef-source--image'
          >
            <img
              src='https://level-contractor.thepreview.pro/images/logo-w.png'
              className='qodef-header-logo-image qodef--main'
              alt='logo main'
            />
          </Link>

    

          <div
            onClick={openFullscreen}
            className='qodef-opener-icon qodef-m qodef-source--predefined qodef-fullscreen-menu-opener menu-icon'
          >
            <span className='qodef-m-icon qodef--open'>
              <svg
                className='qodef-svg--menu'
                xmlns='http://www.w3.org/2000/svg'
                width='30'
                height='30'
                viewBox='0 0 84 84'
              >
                <path
                  d='M42.5.5a42 42 0 1 1-42 42 42 42 0 0 1 42-42Z'
                  fill='none'
                  stroke='currentColor'
                ></path>
              </svg>
            </span>
            <span className='qodef-m-icon qodef--close'>
              <svg
                className='qodef-svg--menu-close'
                xmlns='http://www.w3.org/2000/svg'
                width='50'
                height='1'
                viewBox='0 0 50 1'
              >
                <path d='M0 0h50v1H0z'></path>
              </svg>
            </span>
          </div>
          <FullscreenArea isOpen={isFullscreenOpen} onClose={closeFullscreen} />

          {isFullscreenOpen && <FullscreenArea />}
        </div>
      </header>
      <BottomNav />
    </>
  )
}

export default Mheader;
