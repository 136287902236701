import React from 'react';
import { LuMail, LuMapPin, LuPhoneCall } from 'react-icons/lu';

const BottomNav = () => {
  return (
    <div className="bottom-nav">
      <a href='mailto:levelcontractor.office@gmail.com' className="bottom-nav-item">
        <span className="icon"><LuMail /> </span>
        <span>Email</span>
      </a>
      <a href="tel:+18182972920" className="bottom-nav-item">
        <span className="icon"><LuPhoneCall /></span>
        <span>Call</span>
      </a>
      <a href="https://maps.app.goo.gl/wR2UhBSFXRLomvPN6" target="_blank" rel="noopener noreferrer" className="bottom-nav-item">
        <span className="icon"> <LuMapPin /> </span>
        <span>Map</span>
      </a>
    </div>
  );
};

export default BottomNav;
