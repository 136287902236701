import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <hr className="footer-divider" />
      <footer className="footer">
        <div className="footer-container">
          {/* First Column */}
          <div className="footer-column ml-20">
            <Link to="/">
            <img
              src="https://level-contractor.thepreview.pro/images/logo-w.png" // Replace with your logo
              alt="Level Contractor Logo"
              className="footer-logo"
            />
            </Link>
            <p className="license-number">
              <a
                href="https://www.cslb.ca.gov/OnlineServices/checklicenseII/LicenseDetail.aspx?LicNum=1087235"
                target="_blank"
                
                className="license-link"
              >
                License 
              </a> #1087235
            </p>
            <p className="footer-description">
              We are building and remodeling homes in San Ramon and Santa Clara
              counties and parts of the East Bay. See our{" "}
              <Link
                to="/locations"
                
                className="license-link"
              >
                “Locations We Serve”
              </Link>{" "}
              page.
            </p>
          </div>

          {/* Second Column */}  <div className="footer-column">
            <h3>Contact Information</h3>
            <a href="https://maps.app.goo.gl/wR2UhBSFXRLomvPN6">Address: 3494 Camino Tassajara #5212 Danville, CA</a>
            <a href="tel:+18182972920" className="contact">Phone: +1 818-297-2920</a>
            <a href='mailto:levelcontractor.office@gmail.com' className="contact">Email: levelcontractor.office@gmail.com</a>
          </div>

         

          {/* Third Column */}
         <div className="footer-column">
            <h3>Quick Links</h3>
            <ul className="quick-links">
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
                
              </li>
              <li>
                <Link to="/work">Work</Link>
              </li>
              <li>
                <Link to="/project">Projects</Link>               
              </li>
              
              <li>
                <Link to="/contact">Contact</Link>
              
              </li>
              
            </ul>
          </div>
          {/* Fourth Column */}
          <div className="footer-column">
            <h3>Follow Us</h3>
            <div className="social-icons">
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://level-contractor.thepreview.pro/images/social/Twitter.svg" alt="Twitter Icon" />
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://level-contractor.thepreview.pro/images/social/Facebook.svg" alt="Facebook Icon" />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://level-contractor.thepreview.pro/images/social/Insta.svg"
                  alt="Instagram Icon"
                />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://level-contractor.thepreview.pro/images/social/Linkedin.svg"
                  alt="Linkedin Icon"
                />
              </a>
            </div>
            <div className="social-icons">
            <a
                href="https://tiktok.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://level-contractor.thepreview.pro/images/social/Tik-Tok.svg"
                  alt="Linkedin Icon"
                />
              </a>
              <a
                href="https://yelp.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://level-contractor.thepreview.pro/images/social/Yelp.svg" alt="Yelp Icon" />
              </a>
              <a
                href="https://houzz.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://level-contractor.thepreview.pro/images/social/houzz.svg" alt="Houzz Icon" />
              </a>
              <a
                href="https://google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://level-contractor.thepreview.pro/images/social/Google.svg" alt="Google Icon" />
              </a>
            </div>
            <div className="bbb-logo">
              <img
                src="https://level-contractor.thepreview.pro/images/BBB-Logo.png"
                alt="Better Business Bureau Logo"
              />
            </div>
          </div>
        </div>
        <hr className="footer-divider" />
        <div className="footer-bottom">
          <p>
            © {new Date().getFullYear()} Level Contractor. All rights reserved. Designed By <a href="https://zonewebsites.us/" target="_blank" rel="noopener noreferrer">Zone Websites</a>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
