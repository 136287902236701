import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const Services = () => {
  const { href } = useParams();
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);

  // Fetch services from the API
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(
          "https://level-contractor.thepreview.pro/Backend/admin/api/service-api.php"
        );
        setServices(response.data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };
    fetchServices();
  }, []);

  // Handle service selection
  useEffect(() => {
    if (services.length > 0) {
      const service = services.find((s) => s.href === href) || services[0];
      setSelectedService(service);
      if (!href && service) {
        navigate(`/services/${service.href}`, { replace: true });
      }
    }
  }, [href, services, navigate]);

  if (!selectedService) {
    return <div className="error-message">Service not found</div>;
  }

  return (
    <div id="qodef-page-outer">
      <div id="qodef-page-inner" className="qodef-content-full-width">
        <main
          id="qodef-page-content"
          className="qodef-grid qodef-layout--template"
          role="main"
        >
          <div className="qodef-grid-inner clear">
            <div className="qodef-grid-item qodef-page-content-section qodef-col--12">
              <div className="elementor elementor-3070">
                {/* Services Header */}
                <section className="ser-first elementor-section elementor-section-full_width">
                  <div className="elementor-container">
                    <h1>Services</h1>
                  </div>
                </section>

                {/* Services Content */}
                <section className="ser-second elementor-section elementor-section-full_width">
                  <div className="service-detail-page">
                    <div className="sidebar">
                      <ul>
                        {services.map((service) => (
                          <li key={service.id} className="category-buttonsgl">
                            <Link
                              to={`/services/${service.href}`}
                              className={href === service.href ? "active" : ""}
                            >
                              {service.heading}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="service-content">
                      <img
                        src={`https://level-contractor.thepreview.pro/Backend/admin/uploads/${selectedService.image}`}
                        alt={selectedService.heading}
                        className="ser-banner-image"
                        loading="lazy"
                      />
                      <h2>{selectedService.heading}</h2>
                      <p>{selectedService.des}</p>
                      <div className="category-buttons ser-cont">
                        <Link
                          to={`/${selectedService.href}`}
                          className={
                            href === selectedService.href ? "active" : ""
                          }
                        >
                          <button>Read More</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Services;
