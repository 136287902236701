import "./LocationDetailPage.css";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaHammer, FaTools, FaCheckCircle, FaUsers } from "react-icons/fa";
import MultiStepForm from "../MultiStepForm/MultiStepForm";
import { useLocationData } from "./LocationContext";
import Questions from "../Faq/Questions";


const LocationDetailPage = () => {
    const { locationName } = useParams();
    const locations = useLocationData();
    const location = locations[locationName];
    const navigate = useNavigate();

    // const formattedLocationName = locationName
    // .replace(/-/g, ' ') // Replace hyphens with spaces
    // .split(' ')
    // .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    // .join(' ');

    if (!location) {
      return <div>
        <h1>Coming Soon...</h1>
      </div>;
    }

    const { city, state, tagline, descriptionMain, heroImage, bannerSubheading, bannerDescription, bannerImage, bannerImage1, bannerImage2, bannerImage3, bannerImage4, bannerImage5, bannerDes, bannerDes1, bannerDescription1, bannerHeading1, bannerDescription2, bannerHeading2, bannerDescription3, subHeading, description, description1, heading3, subHeading1, heading4, subHeading2, description2, description3, description4, heading5, description5, galleryImages } = location;
    
    const services = [
      {
        title: "Kitchen Remodeling",
        image: "https://level-contractor.thepreview.pro/images/project-page-services/1.webp", // Replace with your actual image URL
        link: "https://level-contractor.thepreview.pro/services/kitchen-remodeling"
      },
      {
        title: "Bathroom Remodeling",
        image: "https://level-contractor.thepreview.pro/images/project-page-services/2.webp",
        link: "https://level-contractor.thepreview.pro/services/bathroom-remodeling"
      },
      {
        title: "Outdoor Patio Remodeling",
        image: "https://level-contractor.thepreview.pro/images/project-page-services/3.webp",
        link: "https://level-contractor.thepreview.pro/services/outdoor-patio-remodeling"
      },
      {
        title: "Roofing",
        image: "https://level-contractor.thepreview.pro/images/project-page-services/4.webp",
        link: "https://level-contractor.thepreview.pro/services/roofing"
      },
      {
        title: "Home Remodelling",
        image: "https://level-contractor.thepreview.pro/images/project-page-services/5.webp",
        link: "https://level-contractor.thepreview.pro/services/home-remodeling"
      },
      {
        title: "Landscaping",
        image: "https://level-contractor.thepreview.pro/images/project-page-services/6.webp",
        link: "https://level-contractor.thepreview.pro/services/landscaping"
      },
    ];
 

  return (
    <div className="location-detail-page">
        {/* Back Button */}
        <span className="category-buttons">
      <button
        onClick={() => navigate(-1)} // Navigate to the previous page
      >
        ← Back to Previous Page
      </button>
      </span>
      {/* Banner Section */}
      <section className="location-banner" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="banner-content">
          <h1>{city}, {state}</h1>
          <p className="banner-subheading"> {tagline} .</p>
          <p className="banner-description">{descriptionMain}</p>
        </div>
      </section>

      <section className="location-cta"><h2>{bannerSubheading}</h2>
      <p>{bannerDescription}</p>
      <p>{bannerDescription1}</p>
      <a href="/contact" className="category-buttons"><button> Get in Touch</button></a>
      </section>

      {/* Project Overview Section */}
      <section className="overview-section">
        <div className="image-text-section">
          <img src={bannerImage} alt="Project Overview" />
          <div className="text-content">
            <h2>{bannerHeading1}</h2>
            <p> {bannerDescription2}</p>
            <p>{bannerDes ? bannerDes : null}</p>
          </div>
        </div>
      </section>

      {/* Project Timeline Section */}
      <section className="timeline-section">
        <div className="image-text-section">
          <div className="text-content">
            <h2>{bannerHeading2}</h2>
            <p> {bannerDescription3}</p>
            <p>{bannerDes1 ? bannerDes1 : null}</p>
            {/* <ul>
              <li><strong>Months 1-2:</strong> Consultation, permits, and design finalization.</li>
              <li><strong>Months 3-5:</strong> Foundation, framing, and structural work.</li>
              <li><strong>Months 6-7:</strong> Electrical, plumbing, and HVAC installations.</li>
              <li><strong>Months 8-9:</strong> Interior finishes, landscaping, and inspection.</li>
            </ul> */}
          </div>
          <img src={bannerImage1} alt="Timeline Highlights" />
        </div>
      </section>

      {/* Workflow Section */}
      <section className="highlights-section">
        <h2>Why Work With Us</h2>
        <div className="highlights-grid">
          <div className="highlight">
            <FaCheckCircle size={40} />
            <h3>Expert Craftsmanship</h3>
            <p>Precision and quality in every detail.</p>
          </div>
          <div className="highlight">
            <FaHammer size={40} />
            <h3> Modern Equipment</h3>
            <p>State-of-the-art tools for flawless execution.</p>
          </div>
          <div className="highlight">
            <FaTools size={40} />
            <h3>Guaranteed Satisfaction</h3>
            <p>Ensuring you're happy with the final result.</p>
          </div>
          <div className="highlight">
            <FaUsers size={40} />
            <h3>Trusted Team Members</h3>
            <p>Professional and reliable experts at your service.</p>
          </div>
        </div>
      </section>


      {/* Before & After Section */}
      <section className="before-after-section">
        <h2>Before & After Transformation</h2>
        <div className="before-after-images">

          <div>
            <img src={bannerImage4} alt="Before Construction" />
            <p>Before Construction</p>
          </div>
          <div>
            <img src={bannerImage5} alt="After Construction" />
            <p>After Construction</p>
          </div>
        </div>
      </section>

      <section className="location-cta">
        <h2> {subHeading}</h2>
        <p>{description}</p>
        <p>{description1}</p>
        <a href="/contact" className="category-buttons"><button> Get in Touch</button></a>
      </section>


     

      {/* Gallery Section */}
      <section className="location-gallery">
        <h2>Gallery of Our Work</h2>
        <div className="location-gallery-grid">
          {galleryImages.map((image, index) => (
            <div key={index} className="location-gallery-item">
              <img src={image.src} alt={image.alt} />
            </div>
          ))}
        </div>
      </section>


      {/* FAQ Section */}
      <Questions />
  
    

      {/* Virtual Walkthrough Section */}
      <section className="video-section">
        <div className="video-content-card">
          <h2>{heading4}</h2>
          <p>{subHeading2}
          </p>
        </div>
        <div className="video-container">
          <img src={bannerImage2} alt="Video Placeholder" className="fallback-image" />
        </div>
      </section>

      <section className="location-cta ">
        <h4>{description2} </h4>
      <p>{description3} </p>
      <p> {description4}</p>
      {/* <a href="/contact" className="category-buttons"><button> Get in Touch</button></a> */}
      </section>

            {/* Call-to-Action Section */}
      <section className="location-cta blur-filter">
        <h2>{heading3}</h2>
        <p>{subHeading1}</p>
        <a href="/contact" className="category-buttons"><button> Get Free Design & Build consultation</button></a>
      </section>



      {/* Project Overview Section */}
      <section className="overview-section">
        <div className="image-text-section">
          <img src={bannerImage3} alt="Project Overview" />
          <div className="text-content">
            <h2>{heading5}</h2>
            <p> {description5}</p>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur dignissimos exercitationem corporis corrupti a incidunt modi animi veritatis? Est, quis.</p> */}
          </div>
        </div>
      </section>

      
      <MultiStepForm />

               {/* Other Services Section */}
      <section className="other-services">
        <h2> Services We Provide</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <img src={service.image} alt={service.title} />
           <Link to={service.link}>
              <h3>{service.title}</h3>
              </Link>
            </div>
          ))}
        </div>
      </section>


    </div>
  );
};

export default LocationDetailPage;
