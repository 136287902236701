import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Create a separate CSS file for styling

const HomeService = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    // Fetch the data from your backend API
    fetch('https://level-contractor.thepreview.pro/Backend/admin/api/home-services-api.php')
      .then((response) => response.json())
      .then((data) => setServices(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="home-service-container">
      <div className="home-service-grid">
        {services.map((service) => (
          <div key={service.id} className="home-service-card">
            <Link to={`/services/${service.href_link}`} className="home-service-image-link">
              <img
                src={`https://level-contractor.thepreview.pro/Backend/admin/uploads/${service.image}`}
                alt={service.hser_heading}
                className="home-service-image"
                loading="lazy"
              /> 
            <div className="home-service-content">
              <h5 className="home-service-heading">{service.hser_heading}</h5>
              <p className='home-service-details-link'>Details</p>
            </div>
            </Link>
           
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeService;
